import {BaseGraph} from "./base_graph";

class OperatorChurn extends BaseGraph {
    constructor() {
        super('operator_churn');
    }

    getChartJsOptions(options, data) {
        options.plugins = {
            tooltip: {
                callbacks: {
                    label: (r) => {

                        let val = r.raw;
                        let explanation = '';

                        if( r.dataset.label.toLowerCase().includes('revenue') ) {
                            val = `${app.DASHBOARD_CORE.num(r.raw,true)}`;
                        } else if( r.dataset.label.toLowerCase().includes('churn') ) {

                            explanation += ' = Companies left / Companies Total: ';
                        }

                        return `${r.dataset.label}${explanation}(${val})`;
                    }
                }
            }
        };

        options.scales = { y: { ticks: { callback: r => { return app.DASHBOARD_CORE.num(r, true); } } } };

        return options;
    }
}

$(() => {
    if(app && app.DASHBOARD_CORE) {
        new OperatorChurn();
    } else {
        console.warn('DashboardCore not initalized.');
    }
});
